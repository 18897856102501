import * as React from "react";
import { Box, Paper, Grid, Typography, ButtonBase } from "@mui/material";
import { get_items, fetch_image } from "./api";
import { useQuery, useQueryClient } from "@tanstack/react-query";
export default function Gallery() {
  const [state, setState] = React.useState([]);
  const { data } = useQuery({
    queryKey: ["items"],
    queryFn: () => get_items(),
    onSuccess: (d) => {
      setState(Array.from(d.data));
    },
  });

  return (
    <Box>
      <Grid container spacing={2} padding={2}>
        {state.map((item) => (
          <Grid item xs={6} md={4} lg={3}>
            <ButtonBase>
              <Paper sx={{ width: "100%", height: "100%", p: 2 }}>
                <Typography variant="h5">{item.item_name}</Typography>
                <Box
                  component="img"
                  src={fetch_image(item.image_id)}
                  sx={{ width: "100%", borderRadius: 2 }}
                />
                <Typography>{item.item_name}</Typography>
              </Paper>
            </ButtonBase>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
