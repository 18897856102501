import { Box,Typography, Grid,Divider } from '@mui/material';
import {WideImage} from './components';
import tri from './resource/try.jpeg';
import books from './resource/books.jpeg';

export default function About() {
  return (
		<Box
			sx={
        			window.screen.width >= 1280 &&
					{px:6}}
	  >
			<AboutUs/ >
	  		<Divider />
	  		<MeetTheTeam />
	  		<Divider />
	  		<MissionStatement />
	  	</Box>
  );
}
function MeetTheTeam(){
	return (
		<Grid
			justifyItems="center"
	  		justifyContent="center"
			alignItems="center"
        	sx={{
				py:8,
				width:'100%',
			}}
		>
		<Grid item container 
        	sx={{
        	}} >
				<Grid item sx={{pb:2}} xs={12} container justifyContent="center">
				  		<Typography variant="h4">
				  			<b>MEET THE TEAM</b>
				  		</Typography>
				</Grid>
				<Grid item xs={12} md={6} container direction="column" justifyContent="center">
					<Grid item>
				  		<Typography variant="h4" align="center">
				  			<b>Bradan Birt</b>
				  		</Typography>
					</Grid>
					<Grid item>
				  		<Typography variant="h5" align="center">
				  			<b>Owner/CEO/CFO</b>
				  		</Typography>
					</Grid>
				  		<Typography sx={{pl:3,pr:3,pt:2, pb:2}} align="center" variant="body">
Bradan founded Big Blue Energy in 2022 and has since worked diligently to create a world class level energy drink company.   He spent his time on perfecting the wonderful taste you know as
Big Blue Energy Drinks.
<br/>
<br/>
Bradan was a college wrestler for 5 years. As an athlete, energy drinks are very common. The taste of most carbonated energy drinks were appealing to his teammates, so he set out to create a perfect flavor/energy blend for his teammates to enjoy.
				  		</Typography>
				</Grid>
				<Grid item xs={12} md={6} container direction="column" justifyContent="center">
					<Grid item>
				  		<Typography variant="h4" align="center">
				  			<b>Thomas McAndrew</b>
				  		</Typography>
					</Grid>
					<Grid item>
				  		<Typography variant="h5" align="center">
				  			<b>Web Developer/CTO</b>
				  		</Typography>
					</Grid>
				  		<Typography sx={{pl:3,pr:3,pt:2, pb:2}} align="center" variant="body">
		Tom is the Chief Technology Officer for Big Blue Energy. He is also responsible for developing this very website, from complete scratch!
<br/>
<br/>
Tom is a University of Iowa State graduate with a degree in computer science. He is a software developer for a  worldwide company in Milwaukee.

<br/>
<br/>
Tom has goals of coding websites for businesses and expanding his presence in the tech world.
Contact us for business inquiries!
				  		</Typography>
				</Grid>
				<Grid item sx={{pt:6}} xs={12} md={6} container direction="column" justifyContent="center">
					<Grid item>
				  		<Typography variant="h4" align="center">
				  			<b>Jackson Healey</b>
				  		</Typography>
					</Grid>
					<Grid item>
				  		<Typography variant="h5" align="center">
				  			<b>Brand Ambassador</b>
				  		</Typography>
					</Grid>
				</Grid>
				<Grid item sx={{pt:6}} xs={12} md={6} container direction="column" justifyContent="center">
					<Grid item>
				  		<Typography variant="h4" align="center">
				  			<b>Quinn Mettert</b>
				  		</Typography>
					</Grid>
					<Grid item>
				  		<Typography variant="h5" align="center">
				  			<b>Sales Ambassador</b>
				  		</Typography>
					</Grid>
				</Grid>
			</Grid>
    </Grid>
	);
}

function MissionStatement(){
	return (
		<Grid container 
	  	justifyContent="center"
        sx={{
			pt:8,
        }} >
			<Grid item xs={8} container justifyContent="center">
			  		<Typography variant="h4" align="center">
			  			<b>MISSION STATEMENT</b>
			  		</Typography>
			  		<Typography sx={
        			window.screen.width >= 1280 &&
					{px:8} } align="center" variant="body">
			At Big Blue Energy Drinks, we believe in empowering individuals to achieve their highest potential. Our mission is to fuel your passion, drive and ambition with our premium, great-tasting energy drinks. Our drinks were created to deliver you a powerful energy boost, without the crash or jitters that come with other energy drinks. We are committed to providing you with the ultimate energy experience in all aspects of your life.
Join the	 Big Blue Energy Drinks movement and experience the difference for yourself!
			  		</Typography>
			</Grid>
		</Grid>
	);
}
function AboutUs() {
	return (
		<Grid 
        sx={{
			px:5,
			pt:7,
			pb:4,
        }} 
	  	container 
		alignItems="center"
	  	justifyContent="center">
	  	<Grid item xs={12} >
	  		<Typography 
				variant="h3"
				align="center"
				sx={{
					pb:4,
				}}
				>
				<b>About Us</b>
	  		</Typography>
    	</Grid>
    	<Grid sx={{

			pt:2,
			maxWidth:'lg'
		}} spacing={6} container alignItems="center" justifyContent="center">
		  	<Grid item xs={12} md={6} container direction="row">
				<WideImage image={tri} name="tri" />	
		  	</Grid>
		  	<Grid item
				xs={12} 
				md={6}
				container 
				direction="row" 
				alignContent="flex-end" 
				justifyContent="center"
				sx={{
					pb:10
				}}
			>
		  		<Typography 
					align="center"
					sx={{pb:2}}
					variant="h4">
		  			<b>Small Business</b>
		  		</Typography>
		  		<Typography sx={window.screen.width >= 1280 &&
					{px:8} } align="center" variant="body">
	  Big Blue Energy is a small business, owned an operated by a recent college athlete/graduate. All marketing, social media, preparing/packaging drinks and daily business operations are all done by hand! This is what makes us special. When you order Big Blue Energy, you aren't ordering from a large corporation, you are ordering from someone just like you! 
<br/>
Big Blue is a small business with big dreams!
		  		</Typography>
		  	</Grid>
    	</Grid>
    	<Grid sx={{pt:2,
			maxWidth:'lg'
		}} spacing={8} container justifyContent="center">
		  	<Grid item 
				xs={12}
				md={6}
				alignContent="flex-end" 
				sx={{pb:20}}
				container direction="row" justifyContent="center">
		  		<Typography 
					sx={{pb:2}}
					align="center"
					variant="h4">
		  			<b>Why Big Blue?</b>
		  		</Typography>
		  		<Typography sx={
        			window.screen.width >= 1280 &&
					{px:8} 
				} align="center" variant="body">
At Big Blue Energy, our goal is to provide you with the ultimate energy and taste experience. We know how important these two things are, and that's why we've created a one of a kind, delicious drink that stands out from the rest.
<br/>
<br/>
Whether you're studying for an exam. preparing for work, hitting the gym or simply need a pick-me-up, our unique formula is designed to give you the energy you need without sacrificing taste or quality!
		  		</Typography>
		  	</Grid>
		  	<Grid item xs={12} md={6} container direction="row">
				<WideImage image={books} name="tri" />	
		  	</Grid>
    	</Grid>
    </Grid>
	);
}
