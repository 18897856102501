import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  get_promo_code,
  get_checkout,
  fetch_image,
  add_to_cart,
  update_line_item,
  delete_line_item,
  post_order_information,
} from "./api";

export default function Checkout() {
  const [promo, setPromo] = useState("");
  const [showCart, setShowCart] = useState(false);
  return (
    <Container maxWidth="sm">
      <ShoppingCart
        promo={promo}
        setPromo={setPromo}
        setShowCart={setShowCart}
      />
      <CheckoutForm promo={promo} showCart={showCart} />
    </Container>
  );
}

export function ShoppingCart(props) {
  const queryClient = useQueryClient();
  const [promo, setPromo] = [props.promo, props.setPromo];
  const [promoModel, setPromoModel] = useState("");
  const { data, status } = useQuery(["checkout", promoModel], () =>
    get_checkout(promo)
  );
  useEffect(() => {
    if (data?.data?.items?.reduce((ps, x) => ps + x.item_count, 0) > 0) {
      props.setShowCart(true);
    } else {
      props.setShowCart(false);
    }
  }, [data, props]);
  const handlePromoCodeApply = async () => {
    setPromoModel("");
    const resp = await get_promo_code(promo);
    setPromoModel(resp.data);
    await queryClient.invalidateQueries({
      queryKey: ["checkout"],
    });
  };

  return (
    <Box sx={{ pt: 2 }}>
      <Typography
        component="h1"
        variant="h4"
        sx={{
          m: 1,
        }}
      >
        Shopping Cart
      </Typography>
      <Paper elevation={3} sx={{ p: 1 }}>
        {status === "success" &&
          data.data.items.map((x) => (
            <Box>
              <Grid
                container
                spacing={1}
                justifyContent="space-between"
                sx={{
                  p: 1,
                }}
              >
                <Grid item xs={3}>
                  <Box
                    component="img"
                    sx={{
                      width: "90%",
                      borderRadius: 2,
                    }}
                    alt={"icon"}
                    src={fetch_image(x.item.image_id)}
                  />
                </Grid>

                <Grid item xs={7} sm={7}>
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="button" component="h2" align="left">
                      {x.item.item_name}
                    </Typography>
                    {x.item_count < 10 ? (
                      <FormControl
                        sx={{
                          pb: 1,
                          minWidth: "65px",
                          width: "20%",
                        }}
                        size="small"
                      >
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          displayEmpty
                          value={x.item_count}
                          label=""
                          onChange={async (e) => {
                            await update_line_item(
                              x.line_item_id,
                              e.target.value
                            );
                            queryClient.invalidateQueries({
                              queryKey: ["checkout"],
                            });
                          }}
                        >
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                          <MenuItem value={3}>3</MenuItem>
                          <MenuItem value={4}>4</MenuItem>
                          <MenuItem value={5}>5</MenuItem>
                          <MenuItem value={6}>6</MenuItem>
                          <MenuItem value={7}>7</MenuItem>
                          <MenuItem value={8}>8</MenuItem>
                          <MenuItem value={9}>9</MenuItem>
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={11}>10+</MenuItem>
                        </Select>
                      </FormControl>
                    ) : (
                      <TextField
                        sx={{
                          minWidth: "65px",
                          width: "20%",
                          pb: 1,
                        }}
                        type="number"
                        size="small"
                        defaultValue={x.item_count}
                        onBlur={async (e) => {
                          await update_line_item(
                            x.line_item_id,
                            e.target.value
                          );
                          queryClient.invalidateQueries({
                            queryKey: ["checkout"],
                          });
                        }}
                      />
                    )}
                  </Box>
                </Grid>

                <Grid item xs={2} sm={2} sx={{}}>
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                  >
                    <Typography variant="button" component="h2" align="right">
                      ${((x.item.item_price * x.item_count) / 100).toFixed(2)}
                    </Typography>
                    <ButtonBase
                      onClick={async (e) => {
                        await delete_line_item(x.line_item_id);
                        queryClient.invalidateQueries({
                          queryKey: ["checkout"],
                        });
                      }}
                      sx={{
                        pb: 2,
                      }}
                    >
                      <DeleteIcon fontSize="medium" />
                    </ButtonBase>
                  </Box>
                </Grid>
              </Grid>
              <Divider />
            </Box>
          ))}
        <Grid
          container
          direction="row"
          alignItems="center"
          alignContent="center"
          spacing={2}
        >
          <Grid item xs={8}>
            <TextField
              margin="normal"
              fullWidth
              id="promoCode"
              label="Promo / Athlete Code"
              size="small"
              name="promocode"
              value={promo}
              onChange={(e) => setPromo(e.target.value)}
            />
            {
              //  <TextField onChange={handlePromoCodeChange} sx={{p:2}}/>
            }
          </Grid>
          <Grid item xs={4}>
            <Button
              onClick={handlePromoCodeApply}
              variant="contained"
              sx={{ mt: 1, width: "100%" }}
            >
              Apply
            </Button>
          </Grid>
        </Grid>
        <Divider />
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignContent: "flex-end",
          }}
        >
          <Typography
            variant="button"
            component="h2"
            align="center"
            sx={{
              m: 1,
            }}
          >
            Sub Total:
          </Typography>
          <Typography
            variant="button"
            component="h2"
            align="center"
            sx={{
              m: 1,
            }}
          >
            ${status === "success" && (data.data.sub_total / 100).toFixed(2)}
          </Typography>
        </Box>

        {promoModel !== "" && (
          <Box>
            {status === "success" && (
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignContent: "flex-end",
                }}
              >
                {data.data.discount_type === 4 ? (
                  <Typography
                    color="#008000"
                    variant="button"
                    component="h2"
                    align="center"
                    sx={{
                      m: 1,
                    }}
                  >
                    {data.data.message}
                  </Typography>
                ) : (
                  <>
                    <Typography
                      color="#008000"
                      variant="button"
                      component="h2"
                      align="center"
                      sx={{
                        m: 1,
                      }}
                    >
                      Discount:
                    </Typography>
                    <Typography
                      color="#008000"
                      variant="button"
                      component="h2"
                      align="center"
                      sx={{
                        m: 1,
                      }}
                    >
                      ${(data.data.discount / 100).toFixed(2)}
                    </Typography>
                  </>
                )}
              </Box>
            )}
            <Divider />
            <Box
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignContent: "flex-end",
              }}
            >
              <Typography
                color="#008000"
                variant="button"
                component="h2"
                align="center"
                sx={{
                  m: 1,
                }}
              ></Typography>
              <Typography
                color="#000080"
                variant="button"
                component="h2"
                align="center"
                sx={{
                  m: 1,
                }}
              >
                $
                {status === "success" && (data.data.new_total / 100).toFixed(2)}
              </Typography>
            </Box>
            <Divider />
          </Box>
        )}

        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignContent: "flex-end",
          }}
        >
          <Typography
            variant="button"
            component="h2"
            align="center"
            sx={{
              m: 1,
            }}
          >
            Tax:
          </Typography>
          <Typography
            variant="button"
            component="h2"
            align="center"
            sx={{
              m: 1,
            }}
          >
            ${status === "success" && (data.data.tax / 100).toFixed(2)}
          </Typography>
        </Box>
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignContent: "flex-end",
          }}
        >
          <Typography
            variant="button"
            component="h2"
            align="center"
            sx={{
              m: 1,
            }}
          >
            Shipping:
          </Typography>
          <Typography
            variant="button"
            component="h2"
            align="center"
            sx={{
              m: 1,
            }}
          >
            ${status === "success" && (data.data.shipping / 100).toFixed(2)}
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignContent: "flex-end",
          }}
        >
          <Typography
            variant="button"
            component="h2"
            align="center"
            sx={{
              m: 1,
            }}
          >
            Total:
          </Typography>
          <Typography
            variant="button"
            component="h2"
            align="center"
            sx={{
              m: 1,
            }}
          >
            ${status === "success" && (data.data.total / 100).toFixed(2)}
          </Typography>
        </Box>
        <Typography
          variant="caption"
          component="h2"
          align="center"
          fontSize="0.55rem"
          sx={{
            m: 1,
          }}
        >
          * Small rounding errors may occur by a few cents due to a difference
          in tax calculations between shopping cart and checkout. Tax is
          properly calculated in 'TOTAL COST' on the next page. You are being
          charged the correct amount.
          <br />
          Please bear with us as we are resolving this issue.
        </Typography>
      </Paper>
    </Box>
  );
}

function CheckoutForm(props) {
  const [email, setEmail] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip_code, setZipCode] = useState("");
  const [address_1, setAddress1] = useState("");
  const [address_2, setAddress2] = useState("");
  const [send_cups, setSendCups] = useState(true);

  let handleSubmit = async (e) => {
    e.preventDefault();
    await post_order_information({
      first_name,
      last_name,
      email,
      phone_number,
      city,
      state,
      zip_code,
      address_1,
      address_2,
      discount_code: props.promo,
      send_cups,
    });
  };
  const vertical = "top";
  const horizontal = "center";
  return (
    <Box sx={{ pt: 4 }}>
      {props.showCart && (
        <>
          <Typography component="h1" variant="h4">
            Shipment Information
          </Typography>
          <Box component="form" onSubmit={handleSubmit} Validate sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  margin="normal"
                  fullWidth
                  id="firstname"
                  label="First Name"
                  name="firstname"
                  value={first_name}
                  onChange={(e) => setFirstName(e.target.value)}
                  autoComplete="firstname"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  margin="normal"
                  fullWidth
                  id="lastname"
                  label="Last Name"
                  name="lastname"
                  value={last_name}
                  onChange={(e) => setLastName(e.target.value)}
                  autoComplete="username"
                />
              </Grid>
            </Grid>
            <TextField
              required
              margin="normal"
              fullWidth
              id="email"
              type="email"
              label="Email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
            />
            <TextField
              required
              margin="normal"
              fullWidth
              id="phonenumber"
              label="Phone Number"
              name="phonenumber"
              value={phone_number}
              onChange={(e) => setPhoneNumber(e.target.value)}
              autoComplete="phonenumber"
            />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  margin="normal"
                  fullWidth
                  id="city"
                  label="City"
                  name="city"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  autoComplete="city"
                />
              </Grid>
              <Grid item xs={6} sm={2}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="State">State</InputLabel>
                  <Select
                    labelId="State"
                    id="state"
                    margin="normal"
                    fullWidth
                    value={state}
                    label="State"
                    required
                    onChange={(e) => setState(e.target.value)}
                  >
                    {States.map((val) => (
                      <MenuItem value={val}>{val}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextField
                  required
                  margin="normal"
                  inputProps={{ pattern: "[0-9]{5}" }}
                  id="zipcode"
                  label="Zip Code"
                  name="zipcode"
                  value={zip_code}
                  onChange={(e) => setZipCode(e.target.value)}
                  autoComplete="zipcode"
                />
              </Grid>
            </Grid>
            <TextField
              required
              margin="normal"
              fullWidth
              id="address1"
              label="Address #1"
              name="address1"
              value={address_1}
              onChange={(e) => setAddress1(e.target.value)}
              autoComplete="address1"
            />
            <TextField
              margin="normal"
              fullWidth
              id="address2"
              label="Address #2"
              name="address2"
              value={address_2}
              onChange={(e) => setAddress2(e.target.value)}
              autoComplete="address2"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={send_cups}
                  onChange={(e) => setSendCups(e.target.checked)}
                />
              }
              label="Include cups with my order"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Continue
            </Button>
          </Box>
        </>
      )}
      {!props.showCart && (
        <>
          <Alert severity="error">Must have an item to checkout</Alert>
        </>
      )}
    </Box>
  );
}

const States = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];
