import { Box } from '@mui/material';
import { Stack } from '@mui/material';
import { Button } from '@mui/material';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export function WideImage(props){
	return (
		<Box
			component="img"
	    	sx={{
				width: '100%',
	    		}}
	    	alt={props.name}
	    	src={props.selected === 0 ? props.defaultImg : props.image}
	    	/>
	);
}

export function VaringStack(props) {
	return (
		<>
		{
			window.screen.width >= 1280 && 
				<Stack
					spacing={6}
					alignContent="center"
					alignItems="center"
					sx={{
						pl:4,
						pr:4,
					}}>
			{props.children}
				</Stack>
		}
		{
			window.screen.width < 1280 && 
				<Stack
					spacing={4}
					alignContent="center"
					alignItems="center"
					sx={{
						pl:2,
						pr:2,
					}}>
			{props.children}
				</Stack>
		}
		</>
	);
}

export function VariableImageBox(props){
	return (
		<>
		{
			window.screen.width >= 1280 && 
				<Box
					component="img"
	    			sx={ props.full ? {
							maxWidth: props.width? props.width : '100%',
	    				} : {
							maxWidth: props.width? props.width : '100%',
							pl:4,
							pr:4,
						}}
	    			alt={props.name}
	    			src={props.image}
	    			/>
		}
		{
			window.screen.width < 1280 && 
				<Box
					component="img"
	    			sx={{
						pl:2,
						pr:2,
						width: '100%',
	    				}}
	    			alt={props.image}
	    			src={props.image}
	    			/>
		}
		</>	
	);
}
export function CustomDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Box>
	  <Stack 
				alignItems="center"
  				justifyContent="center"
				>
      <Button onClick={handleClickOpen()}>{props.linkText}</Button>
	  </Stack>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{props.title}</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {props.useChildren ? props.children : props.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

