import * as React from "react";
import { useLocation } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Home from "./main_page/Home";
import Shop from "./Shop";
import Faq from "./Faq";
import Gallery from "./Gallery";
import Checkout from "./checkout";
import { Routes, Route, Link } from "react-router-dom";
import { Button, ButtonBase, ButtonGroup, IconButton } from "@mui/material";
import { Box } from "@mui/material";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
import { TextField } from "@mui/material";
import logo from "./resource/logo.png";
import { Backdrop } from "@mui/material";
import { SpeedDial } from "@mui/material";
import { SpeedDialIcon } from "@mui/material";
import { SpeedDialAction } from "@mui/material";
import { useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import HomeIcon from "@mui/icons-material/Home";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import QuizIcon from "@mui/icons-material/Quiz";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import About from "./About";
import Header from "./Header";
import { CustomDialog } from "./components";
import { get_checkout, post_feedback, clear_order } from "./api";
import border from "./resource/border.png";
export default function App() {
  return (
    <Box
      sx={{
        zIndex: "1",
        backgroundImage: `url(${border})`,
        backgroundSize: "100%",
        backgroundRepead: "repeat-x",
      }}
    >
      <Header />
      <Routes>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="/shop/:id" element={<Shop />} />
          <Route path="home" element={<Home />} />
          <Route path="faq" element={<Faq />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="checkout" element={<Checkout />} />
          <Route path="contact" element={<Feedback />} />
          <Route path="success" element={<Success />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
      <Footer />
    </Box>
  );
}
function Success() {
  /* here is what I am thinking
   * on the submit in the api we send the response to the api with the
   * orderid so we can mark it as paid, then from there we will redirect to
   * the success page
   */
  clear_order();
  return (
    <Box sx={{ pt: 4 }}>
      <Typography variant="h3" component="h2" align="center">
        Order submitted!
      </Typography>
      <Feedback />
    </Box>
  );
}
function Feedback() {
  const [state, setState] = React.useState({
    name: "",
    content: "",
  });
  const handleSubmit = async () => {
    await post_feedback(state);
    setState({
      name: "",
      content: "",
    });
  };
  return (
    <Box
      container
      sx={{
        pt: 4,
        mx: "auto",
        width: "70%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography variant="h5" component="h2" align="center">
        We would love your feedback about your experience with Big Blue Energy
        and our website.
      </Typography>
      <Typography variant="h5" component="h2" align="center">
        Thank you for your support!
      </Typography>
      <Typography variant="body1" component="h2" align="center">
        For business inquiries or individuals requesting a response, please
        include your contact information and we will get back to you as soon as
        possible!
      </Typography>
      <br />
      <TextField
        fullWidth
        id="name"
        label="Name"
        name="name"
        value={state.name}
        onChange={(e) =>
          setState({ ...state, [e.target.name]: e.target.value })
        }
      />
      <br />
      <TextField
        fullWidth
        multiline
        rows={8}
        id="content"
        label="Response"
        name="content"
        value={state.content}
        onChange={(e) =>
          setState({ ...state, [e.target.name]: e.target.value })
        }
      />
      <br />
      <Button fullWidth variant="contained" onClick={handleSubmit}>
        Submit
      </Button>
    </Box>
  );
}
const withLink = (to, children) => (
  <ButtonBase href={to}>{children}</ButtonBase>
);
const actions = [
  { icon: withLink("/home", <HomeIcon />), name: "Home" },
  { icon: withLink("/about", <InfoIcon />), name: "About Us" },
  { icon: withLink("/shop", <AddShoppingCartIcon />), name: "Shop" },
  { icon: withLink("/faq", <QuizIcon />), name: "FAQ" },
  { icon: withLink("/contact", <ContactPageIcon />), name: "Contact Us" },
  { icon: withLink("/checkout", <ContactPageIcon />), name: "Checkout" },
];
function MobileLogo() {
  return (
    <Grid container justifyContent="center">
      <ButtonBase href="/">
        <Box
          component="img"
          sx={{
            pt: 2,
            width: "50%",
          }}
          alt="social media"
          src={logo}
        />
      </ButtonBase>
    </Grid>
  );
}
const getShoppingCart = async () => {
  const res = get_checkout();
  return res;
};
function CartButton() {
  const { data, status } = useQuery(["checkout_button"], getShoppingCart);
  const location = useLocation();
  console.log(location.pathname);
  return (
    <>
      {status === "success" &&
        !location.pathname.includes("checkout") &&
        data.data.length > 0 && (
          <Button
            href="/checkout"
            variant="contained"
            sx={{
              zIndex: 10000,
              borderRadius: 15,
              position: "fixed",
              bottom: 40,
              right: 40,
            }}
          >
            <ShoppingCartRoundedIcon fontSize="large" color="#ffffff" sx={{}} />
          </Button>
        )}
    </>
  );
}
function MobileNav() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Box>
      <Backdrop open={open} />
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={handleClose}
            sx={{
              whiteSpace: "nowrap",
              minWidth: "auto",
            }}
          />
        ))}
      </SpeedDial>
    </Box>
  );
}
function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

function Disclaimer() {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={6}
    >
      <Grid item>
        <CustomDialog
          title="Disclaimer/Liability"
          linkText="Disclaimer/Liability"
          useChildren
        >
          <Typography align="center">
            <b>Liability and Disclaimer</b>
            <br />
            Thank you for visiting our website and considering our energy
            drinks. Before proceeding with your purchase, we kindly request that
            you carefully read and understand the following liability and
            disclaimer information. By consuming our energy drinks and/or using
            our website, you acknowledge that you have read, understood, and
            agreed to the terms outlined below.
            <br />
            <br />
            Liability Statement: At Big Blue Energy Drinks, we prioritize the
            health and well-being of our customers. However, it is important to
            note that by purchasing and consuming our energy drinks, you do so
            at your own risk. We cannot assume any responsibility or liability
            for any adverse effects, injuries, or health issues that may arise
            from the consumption of our products.
            <br />
            <br />
            Informed Decision: By choosing to consume our energy drinks, you
            affirm that you have made an informed decision. We provide
            comprehensive nutritional and ingredient information on our website,
            allowing you to review and understand the contents of our products.
            It is your responsibility to ensure that you are aware of any
            specific dietary needs or restrictions you may have and to consult
            with a healthcare professional, if necessary, before consuming our
            energy drinks.
            <br />
            <br />
            Individual Health Conditions: We recommend that individuals with
            pre-existing health conditions or concerns consult with a qualified
            healthcare professional before consuming our energy drinks. Our
            products contain caffeine and other ingredients that may affect
            individuals differently. We cannot be held liable for any health
            risks or complications that may arise due to pre-existing health
            conditions, allergies, or sensitivities.
            <br />
            <br />
            Third-Party Responsibility: While we strive to provide accurate and
            up-to-date information, Big Blue Energy Drinks cannot guarantee the
            completeness or accuracy of any third-party information or advice
            that may be available on our website or associated platforms. Any
            reliance on such information is done at your own risk, and we
            disclaim any liability for any damages or losses that may arise as a
            result.
            <br />
            <br />
            Product Usage and Warnings: Our energy drinks are intended for
            individuals that can consume caffeine. We strongly advise against
            excessive consumption and the mixing of our products with alcohol or
            other substances. Though caffeine energy drinks are consumed
            regularly with alcohol, there have been no studies or tests by Big
            Blue Energy Drinks of the effects of the mixture, therefore, we do
            not condone this behavior. It is important to read and follow all
            product information, warnings, and preparation instructions provided
            on our website/packaging to ensure a safe and enjoyable experience.
            <br />
            <br />
            Indemnification: By using our website and purchasing our energy
            drinks, you agree to indemnify and hold Big Blue Energy Drinks, its
            employees, officers, and affiliates harmless from any claims,
            damages, or losses, including legal fees arising from your use of
            our products, website, or any violation of these terms and
            conditions.
            <br />
            <br />
            Conclusion: Big Blue Energy Drinks strives to provide quality
            products and transparent information to our valued customers.
            However, it is essential to understand that by purchasing and
            consuming our energy drinks, you assume all responsibility for any
            associated risks. We recommend reviewing our nutritional and
            ingredient information, consulting with a healthcare professional if
            necessary, and using our products responsibly. If you have any
            questions or concerns, please do not hesitate to contact us.
            <br />
          </Typography>
        </CustomDialog>
      </Grid>
    </Grid>
  );
}

function Footer() {
  return (
    <Box
      sx={{
        pt: 12,
      }}
    >
      <Box sx={{}}>
        <Typography
          align="center"
          variant="subtitle2"
          component="h3"
          sx={{ pt: 2 }}
        >
          © 2023 Big Blue Energy Drinks. All Rights Reserved.
        </Typography>
        <Typography align="center" variant="subtitle2" component="h3">
          We do not sell personal information.
        </Typography>
        <Disclaimer />
        <Typography
          align="center"
          variant="caption"
          component="h3"
          sx={{ pt: 2 }}
        >
          *THESE STATEMENTS HAVE NOT BEEN EVALUATED BY THE FOOD AND DRUG
          ADMINISTRATION. THESE PRODUCTS ARE NOT INTENDED TO DIAGNOSE, TREAT,
          CURE OR PREVENT ANY DISEASE.
        </Typography>
      </Box>
    </Box>
  );
}
