import * as React from "react";
import { 
	Box,
	Accordion,
	AccordionSummary,
	Typography,
	AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
	


export default function Faq(){
  	const [expanded, setExpanded] = React.useState(false);

  	const handleChange =
    	(panel) => (event, isExpanded) => {
      	setExpanded(isExpanded ? panel : false);
    };
	return (
		<Box container sx={{maxWidth:'lg',mx:'auto',p:2}}>
		<Typography variant="h3" align="center">
			<b>FAQs</b>
		</Typography>
    	  <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
    	    <AccordionSummary
    	      expandIcon={<ExpandMoreIcon />}
    	      aria-controls="panel1bh-content"
    	      id="panel1bh-header"
    	    >
    	    <Typography sx={{ width: '100%', flexShrink: 0 }}>
				Are Big Blue Energy Drinks really that awesome?
    	    </Typography>
    	    </AccordionSummary>
    	    <AccordionDetails>
    	      <Typography align="center">
				1000% YES!
				<br/>
				Big Blue Energy Drinks are the most flavorful, exciting energy drinks on the market. Loads of flavor, energy and excitement all in one cup! We will be one of the largest energy drink companies in the world someday!
    	      </Typography>
    	    </AccordionDetails>
    	  </Accordion>

    	  <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
    	    <AccordionSummary
    	      expandIcon={<ExpandMoreIcon />}
    	      aria-controls="panel2bh-content"
    	      id="panel2bh-header"
    	    >
    	    <Typography sx={{ width: '100%', flexShrink: 0 }}>
				How to make the drinks?
			</Typography>
    	    </AccordionSummary>
    	    <AccordionDetails>
    	      <Typography align="center">
				There are lots of ways to make Big Blue Energy Drinks!
				<br/><br/>
				<b>The Big Blue Way</b> (recommended):
				<br/>
				<b>1.</b> Add powder to Big Blue Energy Cup.
				<br/>
				<b>2.</b> Add 3-4oz of warm water and swirl in cup until dissolved.
				<br/>
				<b>3.</b> Fill cup to the top with ice. LOTS OF ICE! (warm water will melt some)
				<br/>
				(ice will melt throughout the day so don't fear you added too much)
				<br/>
				<b>4.</b> Add cold water to the top of the cup.
				<br/>
				<b>5.</b> Put on lid tight and spin the cup until the drink is fully dispersed. ENJOY!
				<br/>
				<br/>

				<b>Other ways:</b>
				<br/>
				Some people add powder to 16-32oz shaker bottles with ice or no ice and shake it up. There is still plenty of flavor even without ice!
				<br/>
				<br/>
				
				People have used their favorite 32oz tumbler with various amounts of ice and water to their liking. Remember, more ice doesn't mean less drink, just more flavor!
				
				<br/>
				<br/>
				Other people have even found success adding the whole packet to a large cup of ice water and stirring with a spoon. Just stir until the powder is all gone. It can be harder to dissolve it in ice cold water!
    	      </Typography>
    	    </AccordionDetails>
    	  </Accordion>
    	  <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
    	    <AccordionSummary
    	      expandIcon={<ExpandMoreIcon />}
    	      aria-controls="panel3bh-content"
    	      id="panel3bh-header"
    	    >
    	      <Typography sx={{ width: '100%', flexShrink: 0 }}>
					Who can consume/handle the energy drinks?
    	      </Typography>
    	    </AccordionSummary>
    	    <AccordionDetails>
    	      <Typography align="center">
				We recommend young children and pregnant women do not drink any form of energy drinks due to the caffeine levels. Ask a doctor if you're allowed.
    	      </Typography>
    	    </AccordionDetails>
    	  </Accordion>
    	  <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
    	    <AccordionSummary
    	      expandIcon={<ExpandMoreIcon />}
    	      aria-controls="panel4bh-content"
    	      id="panel4bh-header"
    	    >
    	    <Typography sx={{ width: '100%', flexShrink: 0 }}>
				How much caffeine is in each drink? Is there a crash?
			</Typography>
    	    </AccordionSummary>
    	    <AccordionDetails>
    	      <Typography align="center">
		Each drink ranges from 220 to 240mg of caffeine. There is ZERO sugar in our drinks which is were most people see crashes from energy drinks. Our drinks are also meant to be drank over a long period of time, so crashes are not an issue with Big Blue Energy.
    	      </Typography>
    	    </AccordionDetails>
			</Accordion>
		<Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
    	    <AccordionSummary
    	      expandIcon={<ExpandMoreIcon />}
    	      aria-controls="panel5bh-content"
    	      id="panel5bh-header"
    	    >
    	    <Typography sx={{ width: '100%', flexShrink: 0 }}>
		Are Big Blue Energy Drinks homemade?
			</Typography>
    	    </AccordionSummary>
    	    <AccordionDetails>
    	      <Typography align="center">
		Yes they are! Big Blue Energy is completely made to order. We have custom product made from our suppliers that we help design to our liking. Then it is shipped to our business where we prepare drinks in shop or in packet form for our online customers! Every time you are, your drinks are made and packaged fresh!
    	      </Typography>
    	    </AccordionDetails>
    	  </Accordion>
		<Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
    	    <AccordionSummary
    	      expandIcon={<ExpandMoreIcon />}
    	      aria-controls="panel6bh-content"
    	      id="panel6bh-header"
    	    >
    	    <Typography sx={{ width: '100%', flexShrink: 0 }}>
		Is there Taurine in the drinks?
			</Typography>
    	    </AccordionSummary>
    	    <AccordionDetails>
    	      <Typography align="center">
		Yes, every Big Blue Energy drink contains Taurine. This amino-acid is found naturally in the body, as well as in meat, fish and other natural products. This has been known to enhance athletic performance. Athletes please be advised.
    	      </Typography>
    	    </AccordionDetails>
    	  </Accordion>
		<Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
    	    <AccordionSummary
    	      expandIcon={<ExpandMoreIcon />}
    	      aria-controls="panel7bh-content"
    	      id="panel7bh-header"
    	    >
    	    <Typography sx={{ width: '100%', flexShrink: 0 }}>
		Are the energy drinks ZERO sugar? Artificial Sweeteners?
			</Typography>
    	    </AccordionSummary>
    	    <AccordionDetails>
    	      <Typography align="center">
		Big Blue Energy Drinks contains zero sugar, which is part of what makes them so awesome! The drinks do contain an assortment of non-nutritive (artificial) sweeteners. Non-nutritive sweeteners are some of the most studied food products in the entire world and are 100% safe for human consumption, especially in the small amount we use them. This helps with the flavorful taste!
<br/>
		Those with the rare hereditary disease PKU, be advised before consuming.
    	      </Typography>
    	    </AccordionDetails>
    	  </Accordion>

		<Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
    	    <AccordionSummary
    	      expandIcon={<ExpandMoreIcon />}
    	      aria-controls="panel8bh-content"
    	      id="panel8bh-header"
    	    >
    	    <Typography sx={{ width: '100%', flexShrink: 0 }}>
		Are the packets homemade? No air?
			</Typography>
    	    </AccordionSummary>
    	    <AccordionDetails>
    	      <Typography align="center">
Yes! Every packet is made by hand from the label, to filling the powder and even sealing them. Big Blue Energy packets have little to no air in them to make them last longer. Don’t worry, even if they feel stiff, they will still open and pour out the same way!
    	      </Typography>
    	    </AccordionDetails>
    	  </Accordion>


		<Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
    	    <AccordionSummary
    	      expandIcon={<ExpandMoreIcon />}
    	      aria-controls="panel9bh-content"
    	      id="panel9bh-header"
    	    >
    	    <Typography sx={{ width: '100%', flexShrink: 0 }}>
How long does it take to package and ship? Shipping Problems?
			</Typography>
    	    </AccordionSummary>
    	    <AccordionDetails>
    	      <Typography align="center">
All orders are made and sent out within 24-48hrs after placing the order. We use standard USPS shipping that typically only takes 2-3 days to receive. Shipping times can vary by a day depending on weekends, holidays and what time of day the order is placed!
If you have any issues with your package, please message us in the CONTACT US tab with your contact information and we will contact you as soon as possible!
    	      </Typography>
    	    </AccordionDetails>
    	  </Accordion>


		</Box>
	);
}
