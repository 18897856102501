import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  ButtonBase,
  Fade,
  Button,
  Typography,
  Stack,
} from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import bg from "../resource/bg.jpeg";

//drink imports
import blackberry_lemonade from "../resource/drinks/blackberry_lemonaid.png";
import blue_green_apple from "../resource/drinks/blue_green_apple.png";
import blue_pineapple from "../resource/drinks/blue_pineapple.png";
import blue_razz_lemonade from "../resource/drinks/blue_razz_lemonade.png";
import cherry_limeaid from "../resource/drinks/cherry_limeaid.png";
import cherry_watermellon from "../resource/drinks/cherry_watermellon.png";
import deep_blue_sea from "../resource/drinks/deep_blue_sea.png";
import grape_big_league_chew from "../resource/drinks/grape_big_league_chew.png";
import grape_strawberry from "../resource/drinks/grape_strawberry.png";
import ice_mango from "../resource/drinks/ice_mango.png";
import kiwi_strawberry from "../resource/drinks/kiwi_strawberry.png";
import lemon_lime from "../resource/drinks/lemon_lime.png";
import nova_punch from "../resource/drinks/nova_punch.png";
import orange_cherry from "../resource/drinks/orange_cherry.png";
import orange_dramsicle from "../resource/drinks/orange_dreamsicle.png";
import peach_mango from "../resource/drinks/peach_mango.png";
import princess_peach from "../resource/drinks/princess_peach.png";
import sour_green_apple from "../resource/drinks/sour_green_apple.png";
import strawberry_pineapple from "../resource/drinks/strawberry_pineapple.png";
import the_big_dawg from "../resource/drinks/the_big_dawg.png";
import the_pink_drink from "../resource/drinks/the_pink_drink.png";
import tropical_orange from "../resource/drinks/tropical_orange.png";
import watermellon_strawberry from "../resource/drinks/tropical_orange.png";

function DrinkList(props) {
  return (
    <Box sx={{ overflow: "clip", width: "100%" }}>
      <DrinkListMain {...props} />
    </Box>
  );
}

function DrinkListMain(props) {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));
  const [className, setClassName] = React.useState("");
  const [state, setState] = React.useState(13);
  // start - 2 = 23
  // full list = 26
  const full_list = 26;
  const onClickLeft = () => {
    var newVal = state - 1;
    if (newVal < 2) {
      newVal = 24;
    }
    setState(newVal);
    setClassName(`move-down-${newVal}`);
  };
  const onClickRight = () => {
    var newVal = state + 1;
    if (newVal > 24) {
      newVal = 2;
    }
    setState(newVal);
    setClassName(`move-up-${newVal}`);
  };
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
      }}
    >
      <Box
        sx={{
          zIndex: -1,
          position: "absolute",
          width: "100%",
          height: "100%",
          backgroundImage: `
linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, .25),
    rgba(255, 255, 255, .5),
    rgba(255, 255, 255, .75),
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 1)
  ), url(${bg})`,
          backgroundImageRepeat: "round",
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
          position: "absolute",
          left: 15,
          bottom: 0,
          top: 0,
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <Button variant={small ? "text" : "contained"} onClick={onClickRight}>
          <ChevronLeftIcon fontSize="large" />
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
          position: "absolute",
          right: 15,
          bottom: 0,
          top: 0,
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <Button variant={small ? "text" : "contained"} onClick={onClickLeft}>
          <ChevronRightIcon fontSize="large" />
        </Button>
      </Box>
      <Stack
        direction="row"
        justifyContent="center"
        sx={{
          py: 10,
          mx: "auto",
          background: `
          radial-gradient(
          ellipse,
            rgba(255, 255, 255, 1),
            rgba(255, 255, 255, .5),
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0)
                )`,
        }}
      >
        {/*

          linear-gradient(
          to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, .5),
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, .5),
    rgba(255, 255, 255, 0)
      )
          */}
        <Typography
          nowrap
          sx={{ display: "inline", pr: 1, zIndex: 100 }}
          variant={small ? "h4" : "h2"}
          align="center"
        >
          <b>A NEW ERA OF</b>
        </Typography>
        <Typography
          sx={{ display: "inline", zIndex: 100 }}
          color="primary"
          variant={small ? "h4" : "h2"}
          align="center"
        >
          <b>ENERGY</b>
        </Typography>
      </Stack>
      <Stack
        sx={{ pb: 10 }}
        className={className}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        {items.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "300px",
              minWidth: "300px",
              maxWidth: "300px",
              zIndex: 100,
              transition: "200ms linear 50ms",
              transform: `${
                full_list - state + 1 === index ? "scale(1.1)" : "scale(0.85)"
              }`,
            }}
          >
            <Stack>
              <Box
                sx={{ width: "250px", m: "auto" }}
                component="img"
                src={item.image}
              />
              <Fade in={full_list - state + 1 === index}>
                <Stack spacing={2} alignItems="center" justifyContent="center">
                  <Typography align="center" variant="h5">
                    <b>{item.name.toUpperCase()}</b>
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (small) {
                        navigate("/Shop/ENERGY%20DRINKS");
                      } else {
                        props.executeScroll();
                      }
                    }}
                  >
                    Buy Here!
                  </Button>
                </Stack>
              </Fade>
            </Stack>
          </Box>
        ))}
      </Stack>
    </Box>
  );
}
const items = [
  {
    image: tropical_orange,
    name: "tropical orange",
  },
  {
    image: blackberry_lemonade,
    name: "blackberry lemonade",
  },
  {
    image: blue_green_apple,
    name: "blue green apple",
  },
  {
    image: blue_pineapple,
    name: "blue pineapple",
  },
  {
    image: blue_razz_lemonade,
    name: "blue razz lemonade",
  },
  {
    image: cherry_limeaid,
    name: "cherry limeade",
  },
  {
    image: cherry_watermellon,
    name: "cherry watermelon",
  },
  {
    image: deep_blue_sea,
    name: "deep blue sea",
  },
  {
    image: grape_big_league_chew,
    name: "grape big league chew",
  },
  {
    image: grape_strawberry,
    name: "grape strawberry",
  },
  {
    image: ice_mango,
    name: "ice mango",
  },
  {
    image: kiwi_strawberry,
    name: "kiwi strawberry",
  },
  {
    image: lemon_lime,
    name: "lemon lime",
  },
  {
    image: nova_punch,
    name: "nova punch",
  },
  {
    image: orange_cherry,
    name: "orange cherry",
  },
  {
    image: orange_dramsicle,
    name: "orange dreamsicle",
  },
  {
    image: peach_mango,
    name: "peach mango",
  },
  {
    image: princess_peach,
    name: "princess peach",
  },
  {
    image: sour_green_apple,
    name: "sour green apple",
  },
  {
    image: strawberry_pineapple,
    name: "strawberry pineapple",
  },
  {
    image: the_big_dawg,
    name: "the big dawg",
  },
  {
    image: the_pink_drink,
    name: "the pink drink",
  },
  {
    image: tropical_orange,
    name: "tropical orange",
  },
  {
    image: blackberry_lemonade,
    name: "blackberry lemonade",
  },
  {
    image: blue_green_apple,
    name: "blue green apple",
  },
  {
    image: blue_pineapple,
    name: "blue pineapple",
  },
  {
    image: blue_razz_lemonade,
    name: "blue razz lemonade",
  },
  {
    image: cherry_limeaid,
    name: "cherry limeade",
  },
  {
    image: cherry_watermellon,
    name: "cherry watermelon",
  },
];
export default DrinkList;
