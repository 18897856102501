import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  IconButton,
  Button,
  ButtonBase,
  Box,
  Typography,
  Stack,
  Slide,
  Paper,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useMediaQuery, useTheme } from "@mui/material";
import ShopItems from "../ShopItems";
import dude_shirt from "../resource/dude_shirt.jpeg";
import double_drinks from "../resource/double_drinkg.jpeg";
import woman_drinking from "../resource/woman_drinking.jpeg";

function ShopCatalog(props) {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));
  if (small) {
    return <ShopCatalogMobile {...props} />;
  } else {
    return <ShopCatalogWeb {...props} />;
  }
}
function ShopCatalogMobile() {
  return (
    <Box
      sx={{
        py: 4,
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Typography sx={{ pb: 8 }} variant="h2" align="center">
        <b>SHOP</b>
      </Typography>
      <Stack spacing={6} justifyContent="center" alignItems="center">
        {[
          { name: "CLOTHING", image: dude_shirt, href: "/Shop/CLOTHING" },
          {
            name: "ENERGY DRINKS",
            image: double_drinks,
            href: "/Shop/ENERGY%20DRINKS",
          },
          { name: "DRINKWARE", image: woman_drinking, href: "/Shop/DRINKWARE" },
        ].map((item, index) => (
          <ButtonBase href={item.href} key={index}>
            <Box sx={{ width: "80%" }}>
              <Box
                sx={{
                  width: "100%",
                  boxShadow: 7,
                }}
                component="img"
                src={item.image}
              />
              <Typography align="center" variant="h5">
                <b>{item.name}</b>
              </Typography>
            </Box>
          </ButtonBase>
        ))}
      </Stack>
    </Box>
  );
}
function ShopCatalogWeb(props) {
  const [state, setState] = React.useState("");
  const containerRef = React.useRef(null);
  const theme = useTheme();
  const large_and_down = useMediaQuery(theme.breakpoints.down("lg"));
  const navigate = useNavigate();
  const handleChange = (val) => {
    if (large_and_down) {
      navigate(`/Shop/${val}`);
    } else {
      setState(val);
    }
  };
  return (
    <Paper
      ref={props.drinkRef}
      elevation={12}
      sx={{
        p: 4,
        m: 4,
        width: "100%",
        position: "relative",
      }}
    >
      <Typography sx={{ pb: 8 }} variant="h2" align="center">
        <b>SHOP </b>
      </Typography>
      <Stack
        spacing={6}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {[
          //for large and down we are using href
          {
            name: "CLOTHING",
            image: dude_shirt,
            item_type_id: 2,
            href: "/Shop/CLOTHING",
          },
          {
            name: "ENERGY DRINKS",
            image: double_drinks,
            item_type_id: 0,
            href: "/Shop/ENERGY%20DRINKS",
          },
          {
            name: "DRINKWARE",
            image: woman_drinking,
            item_type_id: 1,
            href: "/Shop/DRINKWARE",
          },
        ].map((item, index) => (
          <Box key={index} sx={{ width: "25%" }}>
            <Box
              onClick={() => handleChange(item.name)}
              sx={{
                transition: "all .2s linear",
                "&:hover": {
                  transform: "scale(1.02)",
                  boxShadow: 10,
                },
                width: "100%",
                boxShadow: 5,
              }}
              component="img"
              src={item.image}
            />
            <Typography align="center" variant="h5">
              <b>{item.name}</b>
            </Typography>
          </Box>
        ))}
      </Stack>
      <Slide
        sx={{
          position: "absolute",
          zIndex: 100,
          top: 0,
          left: 0,
          right: 0,
          p: 2,
          backgroundColor: "#FFFFFF",
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
        in={state !== ""}
        container={containerRef.current}
      >
        <Box
          sx={{
            zIndex: 100,
            m: "auto",
            backgroundColor: "#FFFFFF",
            border: 2,
          }}
        >
          <Box sx={{ display: "flex" }}>
            <IconButton sx={{ ml: 4 }} onClick={() => handleChange("")}>
              <KeyboardArrowUpIcon />
            </IconButton>
            <Stack
              sx={{ width: "100%" }}
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={4}
            >
              <Typography variant="h2">
                <b>SHOP</b>
              </Typography>
              <Button onClick={() => handleChange("CLOTHING")}>Clothing</Button>
              <Button onClick={() => handleChange("ENERGY DRINKS")}>
                ENERGY DRINKS
              </Button>
              <Button onClick={() => handleChange("DRINKWARE")}>
                DRINKWARE
              </Button>
            </Stack>
          </Box>
          <ShopItems shopType={state} />
        </Box>
      </Slide>
    </Paper>
  );
}

export default ShopCatalog;
