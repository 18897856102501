import * as React from "react";
import { Menu, IconButton } from "@mui/material";

/*
 * hover will popup when mouse hovers over
 * */
function FeedbackPopup(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        size={props.size}
        variant="contained"
        onMouseEnter={props.hover && handleClick}
      >
        {props.icon}
      </IconButton>
      <Menu
        MenuListProps={{ onMouseLeave: handleClose }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {props.children}
      </Menu>
    </div>
  );
}

export default FeedbackPopup;
