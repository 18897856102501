import axios from "axios";

//const API_ROUTE = 'http://api.labsoupllc.com';
const API_ROUTE = "https://api.bigblueenergydrinks.com";
//const API_ROUTE = "http://localhost:5000";
//const API_ROUTE = 'http://192.168.1.150:81';

export function fetch_image(id) {
  return `${API_ROUTE}/images/${id}`;
}
export async function update_line_item(id, count) {
  const response = await axios.put(
    `${API_ROUTE}/line_items/count_update/${id}?count=${count}`
  );
  return response;
}
export async function delete_line_item(id) {
  const response = await axios.delete(`${API_ROUTE}/line_items/${id}`);
  return response;
}
export async function get_checkout(promo) {
  const order_id = localStorage.getItem("order_id");
  const oid =
    order_id === null || order_id === undefined ? 0 : parseInt(order_id);
  const payload = {
    order_id: oid,
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    city: "",
    state: "",
    zip_code: "",
    address_1: "",
    address_2: "",
    discount_code: `${promo}`,
    send_cups: true,
  };

  const response = await axios.post(
    `${API_ROUTE}/order_information/checkout_price`,
    payload
  );
  return response;
}
export async function get_cart_size() {
  const order_id = localStorage.getItem("order_id");
  const oid =
    order_id === null || order_id === undefined ? 0 : parseInt(order_id);
  const response = await axios.get(
    `${API_ROUTE}/line_items/checkout/count/${oid}`
  );
  return parseInt(response.data);
}
export async function get_items() {
  const response = await axios.get(`${API_ROUTE}/items`);
  return response;
}
export async function post_feedback(feedback) {
  feedback.order_history = localStorage.getItem("order_history") ?? "";
  feedback.order_id = localStorage.getItem("order_id") ?? "";
  const response = await axios.post(`${API_ROUTE}/feedback`, feedback);
  return response;
}
export function clear_order() {
  const order_id = localStorage.getItem("order_id");
  const oid =
    order_id === null || order_id === undefined ? 0 : parseInt(order_id);

  const order_history = localStorage.getItem("order_history");
  localStorage.setItem("order_history", `${order_history},${oid}`);
  localStorage.setItem("order_id", 0);
}
export async function post_order_information(order_information) {
  const order_id = localStorage.getItem("order_id");
  const oid =
    order_id === null || order_id === undefined ? 0 : parseInt(order_id);
  order_information.order_id = oid;
  const response = await axios.post(
    `${API_ROUTE}/order_information`,
    order_information
  );
  window.location.href = response.data.href;
  return null;
}
export async function get_promo_code(code) {
  const response = await axios.get(`${API_ROUTE}/validate_code/${code}`);
  console.log(response);
  return response;
}
export async function add_to_cart(line_item, retry_count = 0) {
  const order_id = localStorage.getItem("order_id");
  line_item.order_id =
    order_id === null || order_id === undefined ? 0 : parseInt(order_id);
  const response = await axios
    .post(`${API_ROUTE}/line_items`, line_item)
    .catch(function (er) {
      if (retry_count === 0) {
        localStorage.setItem("order_id", 0);
        return add_to_cart(line_item, 1);
      }
    });

  console.log(response);
  localStorage.setItem("order_id", response.data.order_id);
  return response;
}
