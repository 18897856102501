import * as React from "react";
import { Box, Typography, Grow, Stack } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import hand_made from "../resource/hand_made.png";
import no_crash from "../resource/no_crash.png";
import flavors_24 from "../resource/24_flavors.png";
import brain_boost from "../resource/BrainBoost.png";

/*
 * for phone have the icons and text
 * slide in from the side
 * */
function InfoCard() {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));
  if (small) {
    return <InfoCardMobile />;
  } else {
    return <InfoCardWeb />;
  }
}
function InfoCardMobile() {
  return (
    <Box className="animation-container">
      <Stack
        spacing={5}
        alignItems="center"
        justifyContent="center"
        sx={{ width: "60%", m: "auto" }}
      >
        {items.map((item, index) => (
          <Stack
            alignItems="center"
            justifyContent="center"
            className={index % 2 === 0 ? "slide-item" : "slide-item-left"}
          >
            <Box component="img" sx={{ width: "75%" }} src={item.image} />

            <Typography variant="h5" align="center">
              <b>{item.text}</b>
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
}
function InfoCardWeb() {
  const [open, setOpen] = React.useState("");
  const containerRef = React.useRef(null);
  const handleChange = (value) => {
    setOpen(value);
  };
  return (
    <Stack
      direction="row"
      padding={2}
      spacing={10}
      alignItems="center"
      justifyContent="center"
      sx={{ width: "75%", mt: 4, pb: 12, pt: 6 }}
    >
      {items.map((item, index) => (
        <Box
          key={index}
          onMouseEnter={() => handleChange(item.text)}
          onMouseLeave={() => handleChange(null)}
          sx={{
            transition: "all .25s linear",
            "&:hover": {
              transform: "scale(1.2)",
            },
          }}
        >
          <Box component="img" src={item.image} />

          <Grow in={open === item.text} container={containerRef.current}>
            <Box sx={{ position: "relative", display: "block" }}>
              <Typography
                variant="caption"
                align="center"
                sx={{ width: "100%", position: "absolute", top: 2 }}
              >
                {item.text}
              </Typography>
            </Box>
          </Grow>
        </Box>
      ))}
    </Stack>
  );
}

const items = [
  {
    image: brain_boost,
    text: "Caffeine to boost brain productivity!",
  },
  {
    image: hand_made,
    text: "Every drink specially made to order for you!",
  },
  {
    image: no_crash,
    text: "ZERO SUGAR means there is NO CRASH!",
  },
  {
    image: flavors_24,
    text: "Our #1 priority is taste... So why not have tons of flavors! Each unique in their own way!",
  },
];
export default InfoCard;

/*
 *         {Math.abs(
              item?.ref?.current?.offsetTop +
                item?.ref?.current?.offsetHeight / 2 -
                scrollPosition.center
            ) < 150
              ? "center"
              : item?.ref?.current?.offsetTop +
                  item?.ref?.current?.offsetHeight / 2 >
                scrollPosition.center
              ? "incomming"
              : "exiting"}
    
 * */
