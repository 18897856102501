import * as React from "react";
import { Box, Typography, Stack, Modal } from "@mui/material";
import InfoCard from "./InfoCard";
import ShopCatalog from "./ShopCatalog";
import DrinkList from "./DrinkList";
import home_logo from "../resource/home_logo.png";
import popup from "../resource/popup.jpeg";
import { useMediaQuery, useTheme } from "@mui/material";

function Home() {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(Date.now() < new Date("12/25/2023"));
  const handleClose = () => setOpen(false);
  const drinkRef = React.useRef(null);
  const executeScroll = () =>
    drinkRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  return (
    <Stack
      spacing={2}
      alignItems="center"
      sx={{
        width: "100%",
      }}
    >
      <DrinkList executeScroll={executeScroll} />
      <Box sx={{ width: "50%", pb: 10 }} component="img" src={home_logo} />
      <InfoCard />
      <Typography align="center" variant="h4" sx={{ pt: 4 }}>
        <b>ENERGY + FLAVOR + AWESOMENESS</b>
      </Typography>
      <Typography align="center" variant="h4" sx={{ pb: 8 }}>
        Experience the Big Blue Energy difference with every sip!
      </Typography>
      <ShopCatalog drinkRef={drinkRef} />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={small ? phone_style : style}>
          <Box
            sx={{
              maxWidth: "100%",
            }}
            component="img"
            src={popup}
          />
        </Box>
      </Modal>
    </Stack>
  );
}

const phone_style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  width: "100%",
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};
export default Home;
