import * as React from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import {
  Select,
  Box,
  Stack,
  Grid,
  Typography,
  Snackbar,
  Alert,
  MenuItem,
  TextField,
  Button,
  FormControl,
} from "@mui/material";
import { CustomDialog } from "./components";
import defaultdrink from "./resource/defaultdrink.jpeg";
import defaultshirtlogo from "./resource/default_shirt_logo.jpeg";
import defualt_bottle_logo from "./resource/default_bottle_logo.jpeg";
import default_drinkware from "./resource/default_drinkware.jpeg";
import default_shirt from "./resource/shirts_drink.jpeg";
import defaultlogo from "./resource/defaultlogo.jpeg";
import sample_order from "./resource/sample2.jpeg";
import nutrition_label from "./resource/nutrition.jpeg";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { get_items, fetch_image, add_to_cart } from "./api";
import { WideImage, VariableImageBox, VaringStack } from "./components";

function ShopItems(props) {
  return <ShopItemsWeb {...props} />;
}

function ShopItemsWeb(props) {
  const [initial_load, setLoad] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [items, setItems] = React.useState([]);

  const handleClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const { data, status } = useQuery(["items"], get_items, {
    onSuccess: (data2) => {
      if (initial_load === false) {
        data2.data.forEach((it) => {
          const img = new Image();
          img.src = fetch_image(it?.image_id);
          const img2 = new Image();
          img2.src = fetch_image(it?.image_logo_id);
        });
      }

      setLoad(true);
    },
  });
  const queryClient = useQueryClient();
  const handleSubmit = async (item) => {
    await add_to_cart(item);

    queryClient.invalidateQueries({
      queryKey: ["cart_size"],
    });
    setOpen(true);
  };
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Item added to cart!
        </Alert>
      </Snackbar>
      <Box>
        {props.shopType === "ENERGY DRINKS" && (
          <DrinkLayout
            items={data?.data
              .filter((item) => item.item_type === 0)
              .sort((x, y) => y.item_id - x.item_id)} // Add filter for drinks
            handleSubmit={handleSubmit}
          />
        )}
        {props.shopType === "CLOTHING" && (
          <ClothingLayout
            items={data?.data.filter((item) => item.item_type === 2)}
            handleSubmit={handleSubmit}
          />
        )}
        {props.shopType === "DRINKWARE" && (
          <DrinkwareLayout
            items={data?.data.filter((item) => item.item_type === 1)}
            handleSubmit={handleSubmit}
          />
        )}
      </Box>
    </Box>
  );
}
function DrinkwareLayout(props) {
  const [state, setState] = React.useState({
    item_id: 0,
    item_size: "Large",
    item_count: 1,
  });
  const handleCountChange = (e) => {
    setState({
      ...state,
      item_count: e.target.value,
    });
  };

  const handleChange = (e) => {
    setState({
      ...state,
      item_id: e.target.value,
    });
  };
  const handleSubmit = async () => {
    await props.handleSubmit({
      item_id: state.item_id,
      order_id: 0,
      item_count: state.item_count,
      item_size: state.item_size,
    });
  };
  return (
    <Grid
      sx={{
        justifyContent: "center",
        pt: 2,
      }}
      container
      spacing={3}
    >
      <Grid item md={6} xl={5}>
        <Box
          component="img"
          sx={{ height: "90%", width: "90%", m: "auto" }}
          src={
            state.item_id === 0
              ? default_drinkware
              : fetch_image(
                  props.items.find((x) => x.item_id === state.item_id)?.image_id
                )
          }
          alt="Sweet Bottle"
        />
      </Grid>
      <Grid item xs={4}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            p: 2,
          }}
        >
          <Box component="img" src={defualt_bottle_logo} alt="Sweet Bottle" />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pb: 1,
            pt: 1,
          }}
        >
          {state.item_id !== 0 && (
            <Typography variant="button" sx={{ pr: 1 }}>
              $
              {props.items.find((x) => x.item_id === state.item_id)
                ?.item_price / 100}{" "}
              - Branded Energy Bottle
            </Typography>
          )}
        </Box>

        <ItemSelect
          defaultText="Select A Style"
          items={props.items}
          handleChange={handleChange}
          currentItem={state.item_id}
        />
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={4}>
            <CountSelect
              count={state.item_count}
              handleChange={handleCountChange}
            />
          </Grid>
          <Grid item xs={8}>
            <Button
              sx={{ width: "100%" }}
              variant="contained"
              disabled={state.item_id === 0 ? true : false}
              onClick={handleSubmit}
            >
              ADD TO CART
            </Button>
          </Grid>
        </Grid>

        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              pb: 1,
              pt: 5,
            }}
          >
            <Typography color="primary" variant="button" sx={{ pr: 1 }}>
              What you get
            </Typography>
            <Typography variant="button">
              Big Blue Energy Branded Bottles
            </Typography>
          </Box>
          <Box sx={{ pb: 1 }}>
            <Typography
              variant="caption"
              fontSize="0.8rem"
              align="center"
              component="h2"
            >
              With 8 vibrant colors to choose from, you really can’t go wrong.
              Bottles are labeled to 24oz and can make a Big Blue Energy Drink
              without even having to use ice, though you can if you’d like! If
              you’re always looking for new ways to make your Big Blue Energy,
              this one is perfect!
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
function ClothingLayout(props) {
  const [state, setState] = React.useState({
    item_id: 0,
    item_size: "Large",
    item_count: 1,
  });
  const handleChange = (e) => {
    setState({
      ...state,
      item_id: e.target.value,
    });
  };
  const handleCountChange = (e) => {
    setState({
      ...state,
      item_count: e.target.value,
    });
  };
  const handleSubmit = async () => {
    await props.handleSubmit({
      item_id: state.item_id,
      order_id: 0,
      item_count: state.item_count,
      item_size: state.item_size,
    });
  };
  return (
    <Grid
      sx={{
        justifyContent: "center",
        height: "100%",
        pt: 2,
      }}
      container
      spacing={3}
    >
      <Grid item md={6} xl={5}>
        <Box
          component="img"
          sx={{ height: "90%", width: "90%", m: "auto" }}
          src={
            state.item_id === 0
              ? default_shirt
              : fetch_image(
                  props.items.find((x) => x.item_id === state.item_id)?.image_id
                )
          }
          alt="Sweet T-Shirt"
        />
      </Grid>
      <Grid item xs={4}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            p: 2,
          }}
        >
          <Box component="img" src={defaultshirtlogo} alt="Sweet Bottle" />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pb: 1,
            pt: 1,
          }}
        >
          {state.item_id !== 0 && (
            <Typography variant="button" sx={{ pr: 1 }}>
              $
              {props.items.find((x) => x.item_id === state.item_id)
                ?.item_price / 100}{" "}
              - Branded T-Shirt
            </Typography>
          )}
        </Box>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={4}>
            <CountSelect
              count={state.item_count}
              handleChange={handleCountChange}
            />
          </Grid>
          <Grid item xs={8}>
            <SizeSelect
              size={state.item_size}
              handleChange={(e) => {
                setState({
                  ...state,
                  item_size: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ItemSelect
              defaultText="Select A Style"
              items={props.items}
              handleChange={handleChange}
              currentItem={state.item_id}
            />
          </Grid>
        </Grid>
        <Button
          sx={{ width: "100%" }}
          variant="contained"
          disabled={state.item_id === 0 ? true : false}
          onClick={handleSubmit}
        >
          ADD TO CART
        </Button>

        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              pb: 1,
              pt: 5,
            }}
          >
            <Typography color="primary" variant="button" sx={{ pr: 1 }}>
              What you get
            </Typography>
            <Typography variant="button">50/50 cotton/poly t-shirt</Typography>
          </Box>
          <Box sx={{ pb: 1 }}>
            <Typography
              variant="caption"
              fontSize="0.8rem"
              align="center"
              component="h2"
            >
              Rock your favorite energy drink brand anywhere you go! These
              t-shirts are pre-shrunk and standard sized. You should get your
              normal shirt size. If you are between two sizes, get the larger of
              the two.
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
function DrinkLayout(props) {
  const [state, setState] = React.useState(0);
  const [count, setCount] = React.useState(1);

  const changeItem = (e) => {
    setState(e.target.value);
  };
  const changeCount = (e) => {
    setCount(parseInt(e.target.value));
  };
  const onSubmit = async () => {
    await props.handleSubmit({
      item_id: state,
      order_id: 0,
      item_count: count,
    });
  };
  return (
    <Grid
      sx={{
        justifyContent: "center",
        height: "100%",
        pt: 2,
      }}
      container
      spacing={3}
    >
      <Grid item md={6} xl={5}>
        <Box
          component="img"
          sx={{ height: "80%", width: "80%", m: "auto" }}
          src={
            state === 0
              ? defaultdrink
              : fetch_image(
                  props.items.find((x) => x.item_id === state)?.image_id
                )
          }
          alt="delicious big blue energy"
        />
      </Grid>
      <Grid item xs={4}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "40%",
          }}
        >
          <Box
            component="img"
            sx={{ height: "100%" }}
            src={
              state === 0
                ? defaultlogo
                : fetch_image(
                    props.items.find((x) => x.item_id === state)?.image_logo_id
                  )
            }
            alt="delicious big blue energy"
          />
        </Box>
        <ItemPageInfo
          items={props.items}
          iid={state}
          count={count}
          changeCount={changeCount}
          changeItem={changeItem}
          handleSubmit={onSubmit}
        />
      </Grid>
    </Grid>
  );
}
function ItemPageInfo({
  changeItem,
  changeCount,
  count,
  items,
  iid,
  handleSubmit,
}) {
  var item = items?.find((x) => x.item_id === iid);

  return (
    <Box>
      {item !== null && item !== undefined && (
        <Typography variant="button" component="h2" align="center">
          ${item.item_price / 100} - {item.item_slogan}
        </Typography>
      )}
      <Grid
        container
        sx={{ pt: 1 }}
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <Grid item xs={6}>
          <ItemSelect
            defaultText="Select A Flavor"
            items={items}
            handleChange={changeItem}
            currentItem={iid}
          />
        </Grid>
        <Grid item xs={2}>
          <CountSelect count={count} handleChange={changeCount} />
        </Grid>
        <Grid item xs={4}>
          <Button
            sx={{ width: "100%" }}
            variant="contained"
            disabled={iid === 0 ? true : false}
            onClick={handleSubmit}
          >
            ADD TO CART
          </Button>
        </Grid>
      </Grid>
      <Typography sx={{ pt: 1 }} variant="button" component="h2" align="center">
        Makes a 32oz drink with 220mg - 240mg Caffeine
      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <Typography
            fontSize="0.85rem"
            color="primary"
            variant="caption"
            component="h2"
            align="center"
          >
            <b>WHAT YOU GET</b>
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            fontSize="0.85rem"
            variant="caption"
            component="h2"
            align="center"
          >
            <b>EVERYTHING BUT ICE AND WATER!</b>
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            fontSize="0.6rem"
            variant="caption"
            component="h2"
            align="center"
          >
            <b>Quantity 1 = ONE drink pack (Min. 6 per order)</b>
          </Typography>
        </Grid>
      </Grid>
      <Typography
        sx={{ pb: 1 }}
        fontSize="0.8rem"
        variant="caption"
        component="h2"
        align="center"
      >
        Select what flavors you want and how many of each. You'll receive the
        drink packs, an allotted # of cup sets and instructions to make your
        energy drinks just like we do in our shop! Only thing you will need is
        ice and water!
      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={6}
      >
        <Grid item xs={3}>
          <IngredientPopup />
        </Grid>
        <Grid item xs={3}>
          <CustomDialog title="NUTRITION" linkText="NUTRITION" useChildren>
            <WideImage image={nutrition_label} />
          </CustomDialog>
        </Grid>

        <Grid item xs={6}>
          <CustomDialog
            title="SAMPLE ORDER"
            linkText="NEED HELP? CLICK TO SEE A SAMPLE ORDER"
            useChildren
          >
            <VariableImageBox image={sample_order} name="why us" />
          </CustomDialog>
        </Grid>
      </Grid>
    </Box>
  );
}
function IngredientPopup() {
  return (
    <CustomDialog
      title="INGREDIENT LIST"
      linkText="INGREDIENT LIST"
      useChildren
    >
      <Typography align="center">
        <b>Big Blue Energy Full Ingredient List</b> <br />
        *This is a complete list of ingredients, not included in every drink.
        All drink recipes contain different arrangements of these ingredients.
        Contact us directly for specific drink flavor ingredient questions.
        <br />
        Ingredients are listed relative to size. Since every ingredient is not
        in every drink, sizes may vary or be slightly off. This is not an
        official or regulated ingredient label due to being a complete list of
        combined ingredients. Also, as weights of each ingredient are not
        perfectly placed in order.
        <br />
        <br />
        Ground Black Tea, Ground Green Tea, Citric Acid, Taurine, Sodium
        Citrate, Malic Acid, Caffeine, Natural and Artificial Fruit Flavors,
        Sucralose, Potassium Phosphate, Glucuronolactone, Fruit Juice Solids,
        Sodium Bicarbonate, Maltodextrin, Potassium Citrate, Stevia, Modified
        Food Starch, Magnesium Oxide, Aspartame, Sodium Citrate, Silicon
        Dioxide, Gum Acacia, Xanthan Gum. Drinks include a small percent of the
        following: Niacinamide (B-Vitamin), D-Calcium Pantothenate (B-Vitamin),
        Cyanocobalamin (B-Vitamin), Folic Acid, Biotin (B-Vitamin), Pyridoxine
        HCL (B-Vitamin), Acesulfame K, Calcium Silicate, Monocalcium Phosphate,
        Salt, Tricalcium Phosphate, Ascorbic Acid (Vitamin C), Dicalcium
        Phosphate, Corn Syrup Solids, Vitamin A & E Acetate, Beta Carotene,
        Artificial Colors, Blue 1, Red 40, Titanium Dioxide, Yellow 5, Yellow 6,
        Caramel Color.
      </Typography>
    </CustomDialog>
  );
}

function SizeSelect(props) {
  const [state, setState] = React.useState("Large");
  React.useEffect(() => setState(props.size), [props.size]);
  const handleChange = (e) => {
    setState(e.target.value);
    props.handleChange(e);
  };
  return (
    <FormControl sx={{ m: 0, minWidth: 50, width: "100%" }} size="small">
      <Select displayEmpty value={state} label="" onChange={handleChange}>
        {["Small", "Medium", "Large", "Extra Large", "2XL", "3XL"].map(
          (item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
}
function CountSelect(props) {
  const [state, setState] = React.useState(1);
  React.useEffect(() => setState(props.count), [props.count]);
  const handleChange = (e) => {
    setState(e.target.value);
    props.handleChange(e);
  };
  return (
    <>
      {state < 10 ? (
        <FormControl sx={{ m: 0, minWidth: 50, width: "100%" }} size="small">
          <Select displayEmpty value={state} label="" onChange={handleChange}>
            {[...Array(10).keys()].map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
            <MenuItem value={11}>10+</MenuItem>
          </Select>
        </FormControl>
      ) : (
        <TextField
          size="small"
          type="number"
          defaultValue={state}
          onBlur={handleChange}
        />
      )}
    </>
  );
}

function ItemSelect(props) {
  const [state, setState] = React.useState(0);
  const [items, setItems] = React.useState([]);
  const handleChange = (e) => {
    setState(e.target.value);
    props.handleChange(e);
  };
  React.useEffect(() => setItems(props.items), [props.items]);
  React.useEffect(() => setState(props.currentItem), [props.currentItem]);
  return (
    <FormControl sx={{ width: "100%" }} size="small">
      <Select displayEmpty value={state} label="" onChange={handleChange}>
        <MenuItem value={0}>{props.defaultText}</MenuItem>
        {items?.map((it) => (
          <MenuItem key={it.item_id} value={it.item_id}>
            {it.item_name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
export default ShopItems;
